import { Link, useNavigate } from "@remix-run/react";
import type { ReactNode } from "react";
import { tv } from "tailwind-variants";

export type CTAProps = {
  children: ReactNode;
  fillStyle?: "filled" | "outline";
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  target?: string;
  to?: string;
  variant?:
    | "coral"
    | "coral-shadow"
    | "coral-white"
    | "sky"
    | "sky-shadow"
    | "green"
    | "orange";
  type?: "button" | "submit";
  disabled?: boolean;
  id?: string;
  customDisabledClassName?: string;
};

export function CTARedirect(
  props: CTAProps & {
    userLoggedIn: boolean;
    loginOrSignUp: "login" | "sign-up";
  }
) {
  return (
    <CTA
      {...{
        ...props,
        to: props.userLoggedIn
          ? props.to
          : `/${props.loginOrSignUp}?redirectTo=${encodeURIComponent(
              props.to ?? ""
            )}`,
      }}
    />
  );
}

const tvCTA = tv({
  base: "rounded-3xl px-3 whitespace-nowrap md:px-4  outline-none",
  variants: {
    fillStyle: {
      filled: "bg-sky-600 text-white py-1 md:py-1.5",
      outline: "bg-white border-2 sky-600 py-.5 md:py-1",
    },
    variant: {
      "coral-shadow": "",
      coral: "",
      "coral-white": "",
      sky: "",
      "sky-shadow": "",
      green: "",
      orange: "",
    },
    disabled: {
      true: "",
    },
  },
  compoundVariants: [
    {
      fillStyle: "filled",
      variant: "coral",
      class: "bg-coral",
    },
    {
      fillStyle: "filled",
      variant: "coral-shadow",
      class:
        "bg-coral hover:shadow-lg hover:shadow-coral/50 ease-in duration-300",
    },
    {
      fillStyle: "filled",
      variant: "coral-white",
      class: "bg-white text-coral",
    },
    {
      fillStyle: "filled",
      variant: "sky-shadow",
      class:
        "bg-sky-600 hover:shadow-lg hover:shadow-sky-600/50 ease-in duration-300",
    },
    {
      fillStyle: "filled",
      variant: "sky",
      class: "bg-sky-600",
    },
    {
      fillStyle: "filled",
      variant: "green",
      class: "bg-green-500",
    },
    {
      fillStyle: "filled",
      variant: "orange",
      class: "bg-orange-500",
    },
    {
      fillStyle: "outline",
      variant: "coral",
      class:
        "text-coral border-coral hover:text-coral/80 hover:bg-coral-50/50 ease-in duration-300",
    },
    {
      fillStyle: "outline",
      variant: "sky",
      class:
        "text-sky-600 border-sky-600 hover:text-sky-600/80 hover:bg-sky-50/50 ease-in duration-300",
    },
    {
      fillStyle: "outline",
      variant: "green",
      class:
        "text-green-500 border-green-500 hover:text-green-500/80 hover:bg-green-50/50 ease-in duration-300",
    },
    {
      fillStyle: "outline",
      variant: "orange",
      class:
        "text-orange-500 border-orange-500 hover:text-orange-500/80 hover:bg-orange-50/50 ease-in duration-300",
    },
    {
      fillStyle: ["filled", "outline"],
      variant: [
        "coral",
        "sky",
        "green",
        "orange",
        "coral-shadow",
        "sky-shadow",
        "coral-white",
      ],
      disabled: true,
      class:
        "cursor-not-allowed bg-neutral-200 text-gray-400 hover:bg-neutral-200 hover:shadow-none",
    },
  ],
});

export function CTA({
  fillStyle = "filled",
  variant = "coral",
  children,
  className,
  onClick,
  target,
  to,
  type = "button",
  disabled = false,
  id,
  customDisabledClassName,
}: CTAProps) {
  const navigate = useNavigate();
  const classes = tvCTA({
    fillStyle,
    variant,
    className: `${className} ${disabled ? customDisabledClassName : ""}`,
    disabled: disabled && !customDisabledClassName,
  });
  onClick =
    onClick ??
    (to
      ? () => {
          if (to.startsWith("http")) {
            window.location.href = to;
          } else {
            navigate(to);
          }
        }
      : () => {});
  return target && to ? (
    <Link to={to} id={id} target={target} className={classes}>
      {children}
    </Link>
  ) : (
    <button
      id={id}
      onClick={onClick}
      className={classes}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
